import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import { _hmtHandle } from '@/utils/utils'
import moment from 'moment'
import { Clock } from 'grommet'
import { Toast } from 'antd-mobile'
import appState from '@/store/appState'
import primaryMarket from '@/store/primaryMarket'

import BuyModal from '../../../component/buyModal/buyModal'

import './card.scss'

const Card: React.FC = () => {
  const [state, setState] = useState({
    buyModalVisibe: false
  })
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  const cardShowObj = primaryMarket.hotCollectionList.find(x => x.show_in_index === true)

  useEffect(() => {
    primaryMarket.getHotCollectionList()
    _hmtHandle('任何设备', '访问', '闪卡主页')
  }, [])


  const [countdown, setCountdown] = useState<any>(null); // 以秒为单位，表示倒计时总时间

  useEffect(() => {
    if (cardShowObj?.time_begin) {
      const endTime = moment(cardShowObj?.time_begin).valueOf(); // 将结束时间转换为时间戳
      setCountdown(`¥ ${cardShowObj?.price}/张 购买${cardShowObj?.title}`)
      // 创建一个定时器，每秒减少一秒倒计时时间
      const timer = setInterval(() => {
        const now = moment().valueOf(); // 获取当前时间的时间戳
        // 将倒计时时间转换为"00:00"格式
        if (endTime < now) {
          setCountdown(`¥ ${cardShowObj?.price}/张 购买${cardShowObj?.title}`)
          clearInterval(timer);
          return
        }

        // 计算两个时间之间的差值，以秒为单位
        let timeRemaining = Math.floor((endTime - now) / 1000);

        timeRemaining = timeRemaining - 1

        if (timeRemaining > 3600) {
          return setCountdown(moment(endTime).format('HH:mm') + ` 开启${cardShowObj?.title}抢购`)
        }

        if (timeRemaining <= 0) {
          setCountdown(`¥ ${cardShowObj?.price}/张 购买${cardShowObj?.title}`)
          clearInterval(timer);
          return
        }

        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;

        setCountdown((
          <>
            倒计时&nbsp;
            <Clock
              type="digital"
              precision="minutes"
              time={`T${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
            />
            &nbsp;后开启抢购
          </>
        ))
      }, 1000);

      // 清除定时器
      return () => {
        clearInterval(timer);
      };
    }
  }, [cardShowObj]);


  useEffect(() => {
    appState.getNftNotice('').then(data => {
      if (data.code === 0) {
        if (localStorage.getItem('noticeLength')) {
          if (Number(localStorage.getItem('noticeLength')) < data.data.length) {
            setVisible(true)
          }
        } else {
          setVisible(true)
        }
      }
    })
  }, [])


  return (
    <div id="Card">
      <header>
        <div style={{ width: 30 }}>
          <img height={40} src={require('../../../assets/imgs/title.png')} alt="" />
        </div>
        <div className='Card-header-right'>
          <img height={30.5} width={73} src={require('../../../assets/imgs/Frame 1000002273.png')} alt="" onClick={() => navigate('/webview', { state: { src: 'https://wyper.art/download/index.html', text: 'APP下载' } })} />
          <div className='Card-header-right-box' onClick={() => {
            navigate('/announcement/announcement');
            localStorage.setItem('noticeLength', `${appState.nftNoticeList.length}`)
          }}>
            <img width={16} height={16} src={require('@/assets/imgs/Bell.png')} alt="" />
            <span>公告</span>
            {visible && <i></i>}
          </div>
        </div>
      </header>
      <div className='banner'>
        {/* <img height={210} src={cardShowObj?.index_thumb} alt="" onClick={() => { navigate(`/CommodityDetail?physical_good_id=${cardShowObj?.physical_good_id}`); _hmtHandle('任何设备', '点击', '闪卡图片') }} /> */}

        <div className='card-box' onClick={() => { navigate(`/CommodityDetail?physical_good_id=${cardShowObj?.physical_good_id}`); _hmtHandle('任何设备', '点击', '闪卡图片') }}>
          <img src={require('../../../assets/imgs/homecard1.png')} alt="" />
          <img src={require('../../../assets/imgs/homecard2.png')} alt="" />
          <img src={require('../../../assets/imgs/homecard3.png')} alt="" />
          <img src={require('../../../assets/imgs/homecard4.png')} alt="" />
        </div>
        <div className='banner-btn btn' onClick={() => {
          setState({ ...state, buyModalVisibe: true }); _hmtHandle('任何设备', '点击', '购买纪元闪卡')
        }}>
          {countdown}
          {/* <img height={95} className='banner-btn-img animate__animated animate__pulse' src={require('../../../assets/imgs/givecard.png')} alt="" /> */}
        </div>
      </div>
      <div className='box1'>
        <span className='box1-title'>原卡玩法</span>
        <div className='box1-content'>
          <div className='box1-content-left btn' onClick={(() => navigate('/mining/CollectionSubscription'))}>
            <div>
              <span className='box1-content-left-text1'>原石申购藏品</span>
              <p className='box1-content-left-text2'>定期申购稀有藏品</p>
            </div>
            <span className='box1-content-left-text3'>立即查看</span>
            <img width={52} src={require('../../../assets/imgs/b.png')} alt="" />
          </div>
          <div className='box1-content-right'>
            <div className='box1-content-right-item btn' onClick={() => navigate('/mining/mygem')}>
              <span className='box1-content-right-item-text1'>开采源矿</span>
              <span className='box1-content-right-item-text2'>得原石</span>
              <img width={49} src={require('../../../assets/imgs/c.png')} alt="" />
            </div>
            <div className='box1-content-right-item btn' onClick={() => navigate('/invite')}>
              <span className='box1-content-right-item-text1'>邀请好友</span>
              <span className='box1-content-right-item-text2'>得源矿</span>
              <img width={50} src={require('../../../assets/imgs/a.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='box2'>
        <span className='box2-title'>热门推荐</span>
        <div className='box2-list'>
          {primaryMarket.hotCollectionList?.map((x, i) => (
            <div className='box2-list-item' key={i} onClick={() => navigate(`/CommodityDetail?physical_good_id=${x.physical_good_id}`)}>
              <div className='box2-list-item-img'>
                <img src={x.detail_thumb_list[0]} alt="" />
              </div>
              <div className='box2-list-item-text1'>
                {x.title}
              </div>
              <div className='box2-list-item-text2'>
                <span className='box2-list-item-text2-left'>
                  <span>¥</span> {x.price}
                </span>
                {/* <span className='box2-list-item-text2-right'>
                  剩余 {x.stock}
                </span> */}
              </div>
            </div>
          ))
          }
        </div>
        <div style={{ color: '#888', textDecoration: 'underline', textAlign: 'center', marginTop: 20 }} onClick={() => window.open('https://beian.miit.gov.cn')}>
          沪ICP备2022001874号
        </div>
      </div>
      <BuyModal
        show={state.buyModalVisibe}
        close={() => setState({ ...state, buyModalVisibe: false })}
        physical_good_id={cardShowObj?.physical_good_id}
      />
    </div>
  )
}

export default inject('PrimaryMarket')(observer(Card))